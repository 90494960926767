export const SET_CATEGORIES = 'SET_CATEGORIES';
export const RESET_CATEGORIES = 'RESET_CATEGORIES';
export const SET_CENTER = 'SET_CENTER';
export const SET_ACCESSIBILITY = 'SET_ACCESSIBILITY';
export const SET_DATA_SOURCES = 'SET_DATA_SOURCES';
export const SET_BADGES = 'SET_BADGES';
export const RESET_VALUES = 'RESET_VALUES';
export const SET_SUPERBADGE_DATE_RANGE = 'SET_SUPERBADGE_DATE_RANGE';
export const SET_VERIFIED_DATE_RANGE = 'SET_VERIFIED_DATE_RANGE';
export const CALL_FILTERS = 'CALL_FILTERS';
export const SET_MAPVIEWFILTER = 'SET_MAPVIEWFILTER';
export const SET_BBOX = 'SET_BBOX';
