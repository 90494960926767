export const SET_BUSINESS_DETAILS = 'SET_BUSINESS_DETAILS';
export const SET_SOCIAL_MEDIA = 'SET_SOCIAL_MEDIA';
export const BUSINESS_FILES = 'BUSINESS_FILES';
export const IMAGES_FILES = 'IMAGES_FILES';
export const RESET_MARKETPLACE = 'RESERT_MARKETPLACE';
export const RESET_MARKETPLACE_BUSINESS = 'RESET_MARKETPLACE_BUSINESS';
export const SET_OTHER_QUESTIONS = 'SET_OTHER_QUESTIONS';
export const SET_AVAILABILITY_OPTIONS = 'SET_AVAILABILITY_OPTIONS';
export const SET_QUALITY = 'SET_QUALITY';
export const SET_VISIBILITY = 'SET_VISIBILITY';
export const SET_LOCAL = 'SET_LOCAL';
export const SET_PRODUCE_AVAIL_STORE = 'SET_PRODUCE_AVAIL_STORE';
export const SET_PRODUCE_AVAIL_SEASONALLY = 'SET_PRODUCE_AVAIL_SEASONALLY';
export const SET_CONTACT_NAME = 'SET_CONTACT_NAME';
export const SET_CONTACT_EMAIL = 'SET_CONTACT_EMAIL';
export const SET_CONTACT_OWNER = 'SET_CONTACT_OWNER';
export const SET_CONTACT_PATRON = 'SET_CONTACT_PATRON';
export const SET_NAME = 'SET_NAME';
export const SET_ADDRESS_1 = 'SET_ADDRESS_1';
export const SET_ADDRESS_2 = 'SET_ADDRESS_2';
export const SET_PHONE = 'SET_PHONE';
export const SET_CITY = 'SET_CITY';
export const SET_STATE = 'SET_STATE';
export const SET_ZIPCODE = 'SET_ZIPCODE';
export const SET_WEBSITE = 'SET_WEBSITE';
export const SET_FACEBOOK = 'SET_FACEBOOK';
export const SET_TWITTER = 'SET_TWITTER';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_INSTAGRAM = 'SET_INSTAGRAM';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const SET_SELECT_WIC_ACCEPTED = 'SET_SELECT_WIC_ACCEPTED';
export const SET_SELECT_SNAP_ACCEPTED = 'SET_SELECT_SNAP_ACCEPTED';
export const SET_SELECT_CATEGORY = 'SET_SELECT_CATEGORY';
export const SET_SELECT_CATEGORY_SUPERMARKET = 'SET_SELECT_CATEGORY_SUPERMARKET';
export const SET_SELECT_CATEGORY_CORNER = 'SET_SELECT_CATEGORY_CORNER';
export const SET_SELECT_CATEGORY_DOLLAR = 'SET_SELECT_CATEGORY_DOLLAR';
export const SET_SELECT_CATEGORY_FOOD_PANTRY = 'SET_SELECT_CATEGORY_FOOD_PANTRY';
export const SET_SELECT_CATEGORY_DISTRIBUTION = 'SET_SELECT_CATEGORY_DISTRIBUTION';
export const SET_SELECT_CATEGORY_FOOD_CO_OP = 'SET_SELECT_CATEGORY_FOOD_CO_OP';
export const SET_SELECT_OPERATION = 'SET_SELECT_OPERATION';
